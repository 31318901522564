import React, { useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useShellRootContext } from 'src/contexts/ShellRoot';

type TResponsiveWrapperProps = {
  children: React.ReactNode;
  tablet?: React.ReactNode;
  mobile?: React.ReactNode;
};

const ResponsiveWrapper = React.memo(
  (props: TResponsiveWrapperProps) => {
    const { isWex } = useShellRootContext();
    const lg = 992;
    const md = 768;
    const xs = 576;

    const [sectionWidth, setSectionWidth] = useState(window.innerWidth);
    const isBreakPoint = useMediaQuery({ query: `(min-width: ${lg}px)` });
    const shellNavWidth = useMemo(() => (isBreakPoint ? 260 : 0), [isBreakPoint]);
    const isMobile = useMemo(() => sectionWidth < xs, [shellNavWidth]);
    const isTablet = useMemo(() => sectionWidth < lg && sectionWidth >= md, [shellNavWidth]);

    useEffect(() => {
      const handleResize = () =>
        setSectionWidth(document.getElementById('@jarvis/react-ecp-solutions')?.offsetWidth);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    const memoComponent = useMemo(() => {
      if (isMobile) {
        return props.mobile ?? props.children;
      }
      if (isTablet) {
        return props.tablet ?? props.children;
      }
      // always PC component when larger than tablet
      return props.children;
    }, [isMobile, isTablet, props.mobile, props.tablet, props.children]);

    // Note: Responsive UI for WEX
    if (isWex) {
      return <>{props.children}</>;
    }

    return <>{memoComponent}</>;
  },
  () => true,
);

ResponsiveWrapper.displayName = 'ResponsiveWrapper';

export default ResponsiveWrapper;
