export const store = {
  solutions: {
    START_ROOT_COMPONENT: 'solutions/startRootComponent',
  },
};

export const dateFormat = {
  default: 'MMM DD, YYYY hh:mm:ss A', // March 17th, 2022 02:45:30 PM (12 hours format)
};

export const service = {
  collection: 'collection',
  accountMgt: 'accountMgt',
  commercePortal: 'commercePortal',
  contentBroker: 'contentBroker',
  deviceCache: 'deviceCache',
  deviceShadow: 'deviceShadow',
  fleetMgt: 'fleetMgt',
  programMgt: 'programMgt',
  solutionRegistry: 'solutionRegistry',
  subscriptions: 'subscriptions',
  telemetry: 'telemetry',
  portfolio: 'portfolio',
};

const origin = {
  ECP_LOCAL: 'http://ecp.local',
  DEV: 'https://ecp.dev.portalshell.int.hp.com',
  PIE: 'https://ecp.pie.portalshell.int.hp.com',
  STAGE: 'https://ecp.stage.portalshell.int.hp.com',
  PROD: 'https://hp-commandcenter.com',
};
export const currentStack = {
  DEV: window.location.origin === origin.DEV,
  LOCAL: window.location.origin.includes(origin.ECP_LOCAL),
  PIE: window.location.origin === origin.PIE,
  STAGE: window.location.origin === origin.STAGE,
  PROD: window.location.origin === origin.PROD,
};
