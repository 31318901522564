import React, { useMemo } from 'react';
import ShellRootContext from './ShellRootContext';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isMockUpMode } from 'src/utils/commonMethods';

export const ShellRootProvider = (props) => {
  const flags = useFlags();

  const getFlags = () => {
    if (props.isDemo) {
      return {
        enableAppList: true,
        enableSharedMfeLib: true,
        enableWexTheme: true,
        enableSubscriptionManager: true,
      };
    } else {
      return flags;
    }
  };

  const value = useMemo(
    () => ({
      ecpDeviceV55: props.ecpDeviceV55,
      stack: props.stack ?? 1, // 1 is pie
      shell: props.shell,
      localization: props.localization,
      events: props.events,
      useToast: props.useToast(),
      authProvider: props.authProvider,
      navigation: props.navigation,
      getBreadcrumb: props.getBreadcrumb,
      setBreadcrumb: props.setBreadcrumb,
      removeBreadcrumb: props.removeBreadcrumb,
      tenantId: props.tenantId ?? '',
      featureFlags: getFlags(),
      theme: props.theme,
    }),
    [],
  );
  return <ShellRootContext.Provider value={value}>{props.children}</ShellRootContext.Provider>;
};
