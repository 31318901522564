import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { MfeLoader } from 'src/components/atom';
import { useShellRootContext } from 'src/contexts/ShellRoot';

const SubMfeAppOverview = (props) => {
  const { stack, shell, localization } = useShellRootContext();
  const { appUuid } = useParams();

  const SubMfe = useCallback(
    ({ component, type }) => {
      return (
        <div>
          <MfeLoader
            {...props}
            {...{
              component,
              type,
              stack,
              localization,
              appUuid,
            }}
          />
        </div>
      );
    },
    [shell, stack, props],
  );

  const memoRenderSubMfe = useMemo(() => {
    return (
      <div className="SubMfeAppOverview" data-testid={appUuid ? appUuid : 'sub-mfe-app-overview'}>
        <SubMfe
          type="ECPSolutionAppOverview"
          component={'@jarvis/react-ecp-solutions-app-overview'}
        />
      </div>
    );
  }, []);

  return <>{memoRenderSubMfe}</>;
};

SubMfeAppOverview.defaultProps = {
  shell: {},
  stack: null,
};

SubMfeAppOverview.propTypes = {
  shell: PropTypes.objectOf(PropTypes.any),
  stack: PropTypes.number,
};

export default SubMfeAppOverview;
