import styled from 'styled-components';
import React from 'react';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { AppsMain, SolutionsMain } from 'src/components/organism';

type TSolutionsTemplateProps = {
  solutions: any;
  apps: any;
};

const SolutionsTemplate = (props: TSolutionsTemplateProps) => {
  const { featureFlags } = useShellRootContext();

  return (
    <Wrapper>
      <SolutionsMain solutions={props.solutions} />
      {featureFlags.enableAppList && <AppsMain apps={props.apps} />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  overflow-x: visible;
  overflow-y: visible;
  height: auto;
`;

export default SolutionsTemplate;
