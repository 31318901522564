import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { MfeLoader } from 'src/components/atom';
import { useLocation } from 'react-router-dom';
import { useShellRootContext } from 'src/contexts/ShellRoot';

const SubMfeSecureFleetManager = (props) => {
  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const serviceId = decodeURIComponent(params.get('serviceId') || '');
  const { stack, localization } = useShellRootContext();

  const SubMfe = useCallback(
    ({ component, type }) => {
      return (
        <MfeLoader
          disableContainerPadding
          {...props}
          {...{
            localization,
            component,
            type,
            stack,
            serviceId,
          }}
        />
      );
    },
    [stack, props],
  );

  return (
    <SubMfe
      type="endpointSecurity"
      component="@jarvis/react-ecp-endpointsecurity-homepage"
      data-testid={'secure-fleet-manager-mfe'}
    />
  );
};

SubMfeSecureFleetManager.defaultProps = {
  stack: null,
};

SubMfeSecureFleetManager.propTypes = {
  stack: PropTypes.number,
};

export default SubMfeSecureFleetManager;
