import { service } from 'src/utils/constants';
import { useApiClient } from 'src/api/useApiClient';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { refinedGetPortfolio } from 'src/api/portfolioSvc/portfolioSvcRefinery';
import { getMockUpData, isMockUpMode } from 'src/utils/commonMethods';

/**
 * App Deploy API
 */
export const portfolioSvcApi = () => {
  const { getApiClient } = useApiClient();
  const { tenantId } = useShellRootContext();
  const client = getApiClient(service.portfolio);
  const getPortfolio = async () => {
    let error, response: any;

    if (isMockUpMode()) {
      await getMockUpData('portfolio/get-portfolio').then((data) => {
        response = refinedGetPortfolio(data);
      });
      return { response, error };
    }

    await client
      .getPortfolio(tenantId)
      .then((res) => {
        response = refinedGetPortfolio(res.data);
      })
      .catch((err) => {
        error = err;
      });

    return { response, error };
  };

  return {
    getPortfolio,
  };
};
