import AssetsProviderFactory from '../assets/AssetsProviderFactory';
import { JarvisAuthProvider } from '@jarvis/web-http';
import { storeStartRootComponent } from 'src/store/solutions/action';
import { Grants } from '@jarvis/web-organization/dist/src';
import { useShellRootContext } from 'src/contexts/ShellRoot';

type ManageMfeProps = {
  country?: string;
  language?: string;
  stack?: number;
  authProvider: JarvisAuthProvider;
};

const localProps: ManageMfeProps = {
  language: 'en',
  country: 'US',
  stack: 1,
  authProvider: null,
};

export const setProps = () => {
  const { authProvider, localization, stack } = useShellRootContext();
  localProps.authProvider = authProvider;
  localProps.language = localization.language;
  localProps.stack = stack ?? 1;
  localProps.country = localization.country;
};

export const getProps = (): ManageMfeProps => {
  return localProps;
};

export const t = (subkey: string, args?: Record<string, string>): string => {
  const props = getProps();
  const assetsProvider = AssetsProviderFactory.create(
    props.language || 'en',
    props.country || 'US',
  );
  return assetsProvider.getText(`ecp-solutions.${subkey}`, args);
};

export const refreshSolutionCardAPI = (dispatch) => {
  dispatch(storeStartRootComponent(false));

  setTimeout(() => {
    dispatch(storeStartRootComponent(true));
  }, 100);
};

export const refined = (data: any) => {
  if (data === undefined) {
    return t('unable_to_load_data');
  } else if (data === null || data === '') {
    return '--';
  } else {
    return data;
  }
};

export const getMockUpData = async (moduleName: string) => {
  return await import(`src/mock/${moduleName}.json`);
};

export const isMockUpMode = () => {
  return !!(
    (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') &&
    localStorage.getItem('enable-devices-mockup-data')
  );
};

export const getSolutions = async (
  featureFlags,
  stack,
  authProvider,
  tenantId,
  setSolutions,
  getCollections?,
) => {
  if (featureFlags.enableSharedMfeLib) {
    const grants = new Grants();
    if (isMockUpMode()) {
      let response;
      await getMockUpData('sharedMfeLib/get-shared-mfe-lib').then((data) => {
        response = data.contents;
      });
      setSolutions(response);
      return;
    }

    const solutionsList = await grants.getGrants(stack, authProvider, tenantId);
    const filteredSolutionList = solutionsList.contents.filter((solution: any) => {
      return solution.level != undefined && solution.level == 'DEVICE';
    });
    setSolutions(filteredSolutionList);
  } else {
    const { response: allGroups, error: allGroupsError } = await getCollections(
      0,
      1,
      'All',
      'serviceId:ws-hp.com/smcloud',
    );

    if (allGroupsError !== undefined) {
      setSolutions([]);
      return;
    }

    setSolutions(
      allGroups.contents.map((content) => ({
        grant: 'ws-hp.com/smcloud',
        count: content.devices,
      })),
    );
  }
};

export const getAppsInfo = (appsList, totalAppsInfo) => {
  const newAppsData = [...appsList];

  for (let i = 0; i < newAppsData.length; i++) {
    //search requested app uuid from all app information
    const searchedApp = totalAppsInfo.find((app) => {
      return app.appUuid == newAppsData[i].uuid;
    });

    if (searchedApp != undefined) {
      //search requested locale's description
      const localProps = getProps();
      let description = searchedApp.localeInfoList.find((locale) => {
        return locale.locale == `${localProps.language}_${localProps.country.toUpperCase()}`;
      })?.description;

      if (description == undefined) {
        //If there is no requested locale's description, search english description
        description = searchedApp.localeInfoList.find((locale) => {
          return locale.locale == 'en_US';
        }).description;
      }

      newAppsData[i] = { ...newAppsData[i], icon: searchedApp.icon, description: description };
    } else {
      newAppsData[i] = { ...newAppsData[i], icon: 'noIcon', description: '--' };
    }
  }
  return newAppsData;
};
