import React, { useCallback, useEffect } from 'react';
import { MfeLoader } from 'src/components/atom';
import { useDispatch } from 'react-redux';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { refreshSolutionCardAPI } from 'src/utils/commonMethods';

const GlobalHeader = () => {
  const dispatch = useDispatch();
  const { events, localization } = useShellRootContext();

  const handleReloadPage = () => {
    refreshSolutionCardAPI(dispatch);
  };

  useEffect(() => {
    events.addEventListener('ecp-banner-reload-call', handleReloadPage);
    return () => {
      events.removeEventListener('ecp-banner-reload-call', handleReloadPage);
    };
  }, [events, handleReloadPage]);

  const SubMfe = useCallback(({ component, type }) => {
    return (
      <MfeLoader
        disableContainerPadding
        {...{
          localization,
          component,
          type,
        }}
      />
    );
  }, []);

  const renderGlobalHeader = () => {
    return (
      <div className="react-ecp-header-wrapper">
        <SubMfe type="headerTitle" component="@jarvis/react-ecp-header" />
      </div>
    );
  };

  return renderGlobalHeader();
};

export default GlobalHeader;
