import 'src/styles/global.scss';
import React, { useMemo } from 'react';
import { setProps } from 'src/utils/commonMethods';
import { useDispatch } from 'react-redux';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { useStoreState } from 'src/store/useStoreState';
import { getSolutionsStoreActions } from 'src/store/solutions/init';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { SolutionsPage } from 'src/components/page';
import {
  SubMfeAppOverview,
  SubMfeSecureFleetManager,
  SubMfeSubscriptionManager,
} from './components/molecule';
import { useConstructor } from 'src/hooks/useConstructor';

export const App = (props) => {
  const { solutionsState } = useStoreState();
  const { startRootComponent } = solutionsState;
  const { navigation } = useShellRootContext();
  const baseurl = props.customRelativePath || '/solutions';
  const baseName = navigation ? navigation.createHref({ pathname: baseurl }) : baseurl;
  const dispatch = useDispatch();

  const dispatchAll = (list: any[]) => list.forEach((e) => dispatch(e));

  // init all redux stores
  useConstructor(() => {
    setProps();
    dispatchAll(getSolutionsStoreActions());
  });

  const didReduxUpdated = () => {
    return startRootComponent;
  };

  const renderApp = useMemo(() => {
    if (didReduxUpdated()) {
      return (
        <Router basename={baseName} data-testid="root-component">
          <Switch>
            <Route exact path="/(|solutions)">
              <SolutionsPage />
            </Route>
            <Route path="/security">
              <SubMfeSecureFleetManager {...props} />
            </Route>
            <Route path="/subscription">
              <SubMfeSubscriptionManager {...props} />
            </Route>
            <Route path="/app/:appUuid">
              <SubMfeAppOverview {...props} />
            </Route>
          </Switch>
        </Router>
      );
    } else {
      return <></>;
    }
  }, [didReduxUpdated()]);

  return <>{renderApp}</>;
};
