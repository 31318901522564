import styled from 'styled-components';
import React from 'react';
import { AppsCard } from 'src/components/atom';

type TAppsMainProps = {
  apps: any;
};

export const AppsMain = (props: TAppsMainProps) => {
  return (
    <Wrapper data-testid="apps-main">
      <Contents>
        <AppsCardWrapper data-testid="app-list-card-container">
          {props.apps.map((app) => {
            return <AppsCard key={app.uuid} {...app} />;
          })}
        </AppsCardWrapper>
      </Contents>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 24px;
  height: auto;
`;

const AppsCardWrapper = styled.div`
  display: contents;
`;

const Contents = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px 5px;
`;
