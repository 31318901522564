import React, { useEffect, useMemo, useState } from 'react';
import { GlobalHeader } from 'src/components/molecule';
import { collectionAPI } from 'src/api/collection';
import { CommonLoading } from 'src/components/atom';
import { portfolioSvcApi } from 'src/api/portfolioSvc';
import { contentBrokerAPI } from 'src/api/contentBroker';
import { SolutionsTemplate } from 'src/components/template';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { getAppsInfo, getSolutions, t } from 'src/utils/commonMethods';

const SolutionsPage = () => {
  //public state
  const [solutions, setSolutions] = useState([]);
  const [apps, setApps] = useState([]);

  //private state
  const [isFetchingForSolutions, setIsFetchingForSolutions] = useState(true);
  const [isFetchingForApps, setIsFetchingForApps] = useState(true);
  const [enableRender, setEnableRender] = useState(false);
  const [isErrorForApps, setIsErrorForApps] = useState(false);

  //etc
  const {
    featureFlags,
    stack,
    authProvider,
    tenantId,
    useToast,
    setBreadcrumb,
    removeBreadcrumb,
    getBreadcrumb,
  } = useShellRootContext();
  const { getCollections } = collectionAPI();
  const { getPortfolio } = portfolioSvcApi();
  const { getAppInfo } = contentBrokerAPI();

  const apiGetPortfolio = async () => {
    const { response, error: portfolioError } = await getPortfolio();

    if (portfolioError != undefined) {
      setIsErrorForApps(true);
      return;
    }

    return response.solutions.map((app) => {
      return {
        uuid: app.solutionUuid,
        name: app.solutionName,
        installCountThis: app.installCountThis,
        installCountSub: app.installCountSub,
      };
    });
  };

  const apiGetAppInfo = async (appsList) => {
    const appFilter = {
      propertyName: 'uuid',
      propertyValue: appsList?.map((app) => app.uuid),
    };

    const { response, error: appError } = await getAppInfo(appFilter);

    if (appError != undefined) {
      setIsErrorForApps(true);
      setApps([]);
      return;
    }

    const mappedData = getAppsInfo(appsList, response);
    //dispatch(storeAppsCount(mappedData.length));
    setApps(mappedData);
  };

  const renderError = () => {
    useToast.addToast({
      id: 'error',
      type: 'negative',
      text: t('unable_to_load_data'),
    });
  };

  useEffect(() => {
    const newBreadcrumb = { text: 'Solutions', url: '/solutions', key: 'menu-solutions' };
    if (getBreadcrumb().length > 2) {
      removeBreadcrumb(getBreadcrumb().at(-1).key);
    }

    getSolutions(featureFlags, stack, authProvider, tenantId, setSolutions, getCollections).finally(
      () => {
        setIsFetchingForSolutions(false);
      },
    );

    if (featureFlags.enableAppList) {
      apiGetPortfolio().then((res) => {
        apiGetAppInfo(res)
          .then()
          .finally(() => {
            setIsFetchingForApps(false);
          });
      });
    } else {
      setIsFetchingForApps(false);
    }

    const isExist = getBreadcrumb()
      .map((obj) => obj.text)
      .includes('Solutions');

    if (!isExist) setBreadcrumb(newBreadcrumb);
  }, []);

  useEffect(() => {
    if (!isFetchingForSolutions && !isFetchingForApps) {
      setEnableRender(true);
    } else {
      setEnableRender(false);
    }
  }, [isFetchingForSolutions, isFetchingForApps]);

  useEffect(() => {
    if (isErrorForApps) {
      renderError();
    }
  }, [isErrorForApps]);

  const renderSolutionsPage = useMemo(() => {
    if (enableRender) {
      return <SolutionsTemplate solutions={solutions} apps={apps} />;
    } else {
      return <CommonLoading />;
    }
  }, [enableRender]);

  return (
    <>
      <GlobalHeader />
      {renderSolutionsPage}
    </>
  );
};

export default SolutionsPage;
