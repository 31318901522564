import styled from 'styled-components';
import React from 'react';
import { t } from 'src/utils/commonMethods';
import { SolutionsCard } from 'src/components/atom';
import { useShellRootContext } from 'src/contexts/ShellRoot';

type TSolutionsMainProps = {
  solutions: any;
};

export const SolutionsMain = (props: TSolutionsMainProps) => {
  const { featureFlags } = useShellRootContext();

  const SolutionMap = {
    smcloud: {
      id: 'secure-fleet-manager',
      solutionName: 'HP Secure Fleet Manager',
      description: t('assess_fleet_of_devices'),
      link: '/solutions/security?serviceId=ws-hp.com/smcloud',
    },
    'smcloud-advanced': {
      id: 'secure-fleet-manager-advanced',
      solutionName: 'HP Secure Fleet Manager Advanced',
      description: t('secure_fleet_manager_advanced_description'),
      link: '/solutions/security?serviceId=ws-hp.com/smcloud-advanced',
    },
    subscription: {
      id: 'subscription-manager',
      solutionName: 'Subscription Manager',
      description: 'Description for Subscription Manager',
      link: '/solutions/subscription',
    },
  };

  const solutionNotProvided = (solution: string) => {
    return !Object.keys(SolutionMap).includes(solution);
  };

  return (
    <Wrapper data-testid="solutions-main">
      <Contents>
        {props.solutions.map((solution: any, index: number) => {
          const type = solution.grant.split('/')[1];
          if (solutionNotProvided(type)) {
            return <></>;
          }
          return (
            <SolutionsCard
              key={index}
              data-testid={`solutions-card-${type}`}
              id={SolutionMap[type].id}
              solutionName={SolutionMap[type].solutionName}
              description={SolutionMap[type].description}
              link={SolutionMap[type].link}
              devicesCount={solution.count}
            />
          );
        })}
      </Contents>

      <br />
      {featureFlags.enableSubscriptionManager && (
        <SolutionsCard
          data-testid={`solutions-card-subscription`}
          id={'subscription-manager'}
          solutionName={'Subscription Manager'}
          description={'Description for Subscription Manager'}
          link={'/solutions/subscription'}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 24px;
`;

const Contents = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px 5px;
`;
