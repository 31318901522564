import styled from 'styled-components';
import React, { useCallback } from 'react';
import { t } from 'src/utils/commonMethods';
import { ResponsiveWrapper } from 'src/components/atom/index';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { Card, CustomImage, IconChevronRight } from '@veneer/core';

type TSolutionsCardProps = {
  id: string;
  solutionName: string;
  description: string;
  link: string;
  devicesCount?: number;
};

const SolutionsCard = (props: TSolutionsCardProps) => {
  const { navigation } = useShellRootContext();

  const moveToEndpointSecurity = useCallback(() => {
    navigation.push(props.link);
  }, []);

  // hard coded solution card
  const endpointSecurity = () => {
    return (
      <HardCodedSolutionCardSection
        data-testid={`${props.id}-card-content`}
        onClick={moveToEndpointSecurity}
      >
        <RightSection>
          <IconSection>
            <CustomImage
              size={64}
              quietArea={0}
              src="https://docs.hpcommandcenter.com/images/endpointSecurity.png"
            />
          </IconSection>
          <ContentsSection>
            <SolutionName>{props.solutionName}</SolutionName>
            <Description>{props.description}</Description>
            {props.devicesCount != undefined && (
              <Entitled data-testid={`${props.id}-entitled-device-count`}>
                {`${props.devicesCount} ${t('devices_entitled')}`}
              </Entitled>
            )}
          </ContentsSection>
        </RightSection>

        <LinkSection data-testid={`${props.id}-link`}>
          <IconChevronRight />
        </LinkSection>
      </HardCodedSolutionCardSection>
    );
  };

  const CardProps = {
    id: 'endpointSecurityCard',
    className: 'solutionCard',
    hoverable: true,
    content: endpointSecurity(),
  };

  const TabletCard = () => {
    return <Card {...CardProps} customStyle={{ boxShadow: 'unset', flex: '50%', width: '100%' }} />;
  };

  const MobileCard = () => {
    return <Card {...CardProps} customStyle={{ boxShadow: 'unset', width: '100%' }} />;
  };

  return (
    <ResponsiveWrapper tablet={TabletCard()} mobile={MobileCard()}>
      <Card
        {...CardProps}
        customStyle={{ boxShadow: 'unset', marginRight: '24px', maxWidth: '464px', flex: 1 }}
      />
    </ResponsiveWrapper>
  );
};

const HardCodedSolutionCardSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  position: relative;
  height: 156px;

  cursor: pointer;
`;

const IconSection = styled.div``;

const ContentsSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

const SolutionName = styled.div`
  font-size: 20px;
  font-weight: 400;
`;

const Description = styled.div`
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 25px;
  line-height: 20px;
`;

const Entitled = styled.div`
  font-size: 16px;
  margin-top: 10px;
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: row;
`;

const LinkSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`;

export default SolutionsCard;
