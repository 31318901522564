import styled from 'styled-components';
import React, { useEffect, useRef, useState } from 'react';
import { t } from 'src/utils/commonMethods';
import { Link } from 'react-router-dom';
import { Card, CustomImage, IconChevronRight, IconSmartApp, Tooltip } from '@veneer/core';

type TAppsCardProps = {
  uuid: string;
  name: string;
  description: string;
  icon: string;
  installCountThis: number;
  installCountSub: number;
};

const AppsCard = (props: TAppsCardProps) => {
  const [renderTooltip, setRenderTooltip] = useState(false);
  const elementRef = useRef<HTMLElement>();

  const trackEllipsis = () => {
    const el = elementRef.current;
    /* istanbul ignore next */
    const isEllipsis = el?.scrollHeight > el?.clientHeight;

    if (isEllipsis) {
      setRenderTooltip(true);
    } else {
      setRenderTooltip(false);
    }
  };

  const renderContent = () => {
    return (
      <Description
        data-testid="text-content"
        ref={elementRef}
        onMouseEnter={() => trackEllipsis()}
        onMouseLeave={() => setRenderTooltip(false)}
      >
        {props.description}
      </Description>
    );
  };

  const renderAppList = () => {
    return (
      <Link to={`/app/${props.uuid}`} style={{ textDecoration: 'none', color: '#212121' }}>
        <AppListCardSection data-testid={`app-list-card-${props.uuid}`}>
          <IconSection>
            {props.icon == 'noIcon' ? (
              <IconSmartApp size={64} />
            ) : (
              <CustomImage size={64} quietArea={0} src={props.icon} />
            )}
          </IconSection>

          <ContentsSection>
            <AppName data-testid={`apps-card-app-name-${props.uuid}`}>{props.name}</AppName>
            {renderTooltip ? (
              <Tooltip
                data-testid={`apps-card-tooltip-${props.uuid}`}
                content={
                  <div data-testid={`apps-card-tooltip-content-${props.uuid}`}>
                    {props.description}
                  </div>
                }
                arrow
              >
                {renderContent()}
              </Tooltip>
            ) : (
              <>{renderContent()}</>
            )}
            <Installed data-testid={`installed-device-count-${props.uuid}`}>
              {`${props.installCountThis + props.installCountSub} ${t('devices_installed')}`}
            </Installed>
          </ContentsSection>
          <LinkSection data-testid={`move-to-app-detail-${props.uuid}`}>
            <IconChevronRight />
          </LinkSection>
        </AppListCardSection>
      </Link>
    );
  };

  return (
    <Wrapper key={`${props.uuid}`}>
      <Card
        id={props.uuid}
        className="appCard"
        hoverable
        content={renderAppList()}
        customStyle={{ boxShadow: 'unset', marginRight: '24px' }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 10px;
`;

const AppListCardSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  position: relative;
  width: 474px;
  height: 156px;

  cursor: pointer;

  .appCard {
    min-width: 524px;
  }
`;

const IconSection = styled.div`
  height: 100%;
  padding-top: 30px;
`;

const ContentsSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  width: 100%;
  height: 130px;
`;

const AppName = styled.div`
  font-size: 20px;
  font-weight: 400;
`;

const Description = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 60px;
`;

const Installed = styled.div`
  font-size: 16px;
  margin-top: 5px;
`;

const LinkSection = styled.div`
  margin-left: 10px;
`;

export default AppsCard;
