import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { MfeLoader } from 'src/components/atom';
import { useShellRootContext } from 'src/contexts/ShellRoot';

const SubMfeSubscriptionManager = (props) => {
  const { stack, localization } = useShellRootContext();

  const SubMfe = useCallback(
    ({ component, type }) => {
      return (
        <MfeLoader
          disableContainerPadding
          {...props}
          {...{
            localization,
            component,
            type,
            stack,
          }}
        />
      );
    },
    [stack, props],
  );

  return (
    <SubMfe
      type="subscriptionManager"
      component="@jarvis/react-subscription-manager"
      data-testid={'subscription-manager-mfe'}
    />
  );
};

SubMfeSubscriptionManager.defaultProps = {
  stack: null,
};

SubMfeSubscriptionManager.propTypes = {
  stack: PropTypes.number,
};

export default SubMfeSubscriptionManager;
