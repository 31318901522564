import { service } from 'src/utils/constants';
import { useApiClient } from 'src/api/useApiClient';
import { refinedGetCollections } from './collectionRefinery';
import { getMockUpData, isMockUpMode } from 'src/utils/commonMethods';

export const collectionAPI = () => {
  const { getApiClient } = useApiClient();
  const client = getApiClient(service.collection);

  const getCollections = async (offset, limit, name?, filter?) => {
    let response, error;

    if (isMockUpMode()) {
      await getMockUpData('collections/get-collections').then((data) => {
        response = refinedGetCollections(data);
      });
      return { response, error };
    }

    await client
      .getCollections(
        offset,
        limit,
        undefined,
        name ?? undefined,
        undefined,
        undefined,
        undefined,
        true,
        filter ?? undefined,
      )
      .then((res) => {
        response = refinedGetCollections(res.data);
      })
      .catch((err) => {
        error = err;
      });

    return { response, error };
  };

  return {
    getCollections,
  };
};
