import { service } from 'src/utils/constants';
import { getProps } from 'src/utils/commonMethods';
import {
  Stack,
  PortfolioClient,
  CollectionClient,
  ContentBrokerClient,
} from '@jarvis/react-mfe-component/dist/src';

export const useApiClient = () => {
  const localProps = getProps();
  const stack = localProps.stack;
  const authProvider = localProps.authProvider;

  const getStackPrefix = (stack: Stack): string => {
    switch (stack) {
      case Stack.dev:
        return 'dev';
      case Stack.pie:
        return 'pie';
      case Stack.stage:
        return 'stg';
      case Stack.prod:
        return 'pro';
      default:
        return '';
    }
  };

  const getApiUrl = (hostPrefix: string, basePath: string): string => {
    const defaultBaseUrl = 'tropos-rnd.com';

    return 'https://' + (hostPrefix.length ? hostPrefix + '.' : '') + defaultBaseUrl + basePath;
  };

  const getCustomUrl = (api: string) => {
    return getApiUrl(`stratus-${getStackPrefix(stack)}`, `/${api}/v1`);
  };

  const getApiClient = (serviceName) => {
    let client;
    const contentBrokerEndpoint = getCustomUrl('content-broker-api');
    const portfolioEndpoint = getCustomUrl('portfoliomgt-api');

    switch (serviceName) {
      case service.collection:
        client = new CollectionClient(stack, authProvider);
        break;

      case service.portfolio:
        client = new PortfolioClient(portfolioEndpoint, authProvider);
        break;

      case service.contentBroker:
        client = new ContentBrokerClient(contentBrokerEndpoint, authProvider);
        break;
    }

    return client;
  };

  return {
    getApiClient,
  };
};
